app.run(function($rootScope,$storage,$appService,$translate,$interval,$location,$route,$http,$routeParams) {

	// # Define data

	$rootScope.token  	= $storage.get('token');
	$rootScope.setup  	= $storage.get('setup');
	$rootScope.routes  	= $storage.get('routes');
	$rootScope.user  	= $storage.get('user');
	$rootScope.i18n  	= $storage.get('i18n');
	$rootScope.now 		= moment();

	// # Check existing token

	if($rootScope.token) { $appService.set_token(); }

	// # Loader events

	$rootScope.$on('cfpLoadingBar:started',function() {
		$rootScope.load = true;
	});

	$rootScope.$on('cfpLoadingBar:completed',function() {
		$rootScope.load = false;
	});

	// # Start application

	$appService.do_setup(true).then(function() {
		$appService.do_data(true).then(function() {

			// # Register services

			$appService.do_route();
			$appService.set_session();

		});
	});

	// # Application events

	$rootScope.$on('$routeChangeStart',function(event,nextRoute,currentRoute) {

		// # Set route data

		$rootScope.route  		= nextRoute ? nextRoute.data : currentRoute.data;
		$rootScope.route_last 	= currentRoute ? currentRoute.data : {};

		// # Set route params data

		if($rootScope.route) { $rootScope.route.params = angular.extend({},$routeParams,$location.search()); }

		// # Check protected

		if($rootScope.route && $rootScope.user && $rootScope.user.type_key != 'admin' && $rootScope.user.type_key != 'partner' && ($rootScope.route.key == 'order' || $rootScope.route.key == 'orders')) {
			$location.path('/403');
		} else if($rootScope.route && $rootScope.user && $rootScope.user.type_key != 'admin' && $rootScope.user.type_key != 'rentee' && $rootScope.user.type_key != 'owner' && ($rootScope.route.key == 'ticket' || $rootScope.route.key == 'tickets')) {
			$location.path('/403');
		} else if($rootScope.route && $rootScope.user && $rootScope.user.type_key != 'admin' && ($rootScope.route.key == 'object' || $rootScope.route.key == 'objects')) {
			$location.path('/403');
		} else if($rootScope.route && $rootScope.user && $rootScope.user.type_key != 'admin' && ($rootScope.route.key == 'user' || $rootScope.route.key == 'users')) {
			$location.path('/403');
		}

		// # Check redirect

		if($rootScope.route && $rootScope.route.redirect && $rootScope.user) {
			$location.path($rootScope.route.redirect);
		}

	});

	$rootScope.$on('$viewContentLoaded',function() {

		// # Prevent open mobile navigation

		$('body').click();

	});

	// # Application settings

	$rootScope.tinymceOptions = {
		baseURL: '/app/dist/js/',
		plugins: 'link image code',
		menubar: false,
		toolbar: 'undo redo | bold italic | alignleft aligncenter alignright',
		height: 220,
		language: 'de'
	};

});
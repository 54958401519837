app.config(function($controllerProvider,$locationProvider,$compileProvider,$filterProvider,$httpProvider,$routeProvider,$sceProvider,$qProvider,$translateProvider,$provide,momentPickerProvider) {

	// # Set references

	$routeProviderReference = $routeProvider;
	$qProviderReference = $qProvider;
	$httpProviderReference = $httpProvider;
	$translateProviderReference = $translateProvider;

	// # Disable cache

	if(!$httpProviderReference.defaults.headers.get) { $httpProviderReference.defaults.headers.get = {}; }
	$httpProviderReference.defaults.withCredentials = true;
    $httpProviderReference.defaults.cache = true;

	// # Set HTML5 mode

	$locationProvider.html5Mode(true);

	// # Prevent unhandled rejection error message from angular 1.5.9

	$qProviderReference.errorOnUnhandledRejections(false);

	// # Disable sce restricted urls

	$sceProvider.enabled(false);
	
	// # Register provider

	app.register = {
		controller: $controllerProvider.register,
		directive: 	$compileProvider.directive,
		filter: 	$filterProvider.register,
		factory: 	$provide.factory,
		service: 	$provide.service
	};

	// # Set translation escape strategy

	$translateProvider.useSanitizeValueStrategy('escape');

	// # Set datepicker options

	momentPickerProvider.options({
		locale: 'de',
		today: true
	});

});
app.directive('paging',function($rootScope) {
	return {
		restrict: 'AE', 
		replace: true, 
		scope: {
			page: '=?',
			pageSize: '=?',
			total: '=?',
			limit: '=?',
			callback: '=?'
		},
		templateUrl: $rootScope.setup.template + 'partials/paging.html',
		link: function(scope,element,attrs) {

			// # Define calculation

			function build() {
				
				scope.pages = Math.ceil(scope.total / scope.pageSize) || 1;

			}

			// # Watch changes

			scope.$watchCollection('[page,pageSize,total,limit]', function () {
				build();
			});
		}
	}
});
app.factory('$check',function() {
	return {
		json: function(obj) { try { JSON.parse(obj); } catch(e) { return false; } return true; }, 
		js: function(path) { var isloaded = false; angular.forEach(angular.element(document).find('script'),function(elm) { if(angular.element(elm).attr('src') == path) { isloaded = true; } }); return isloaded; }
	}
});

app.factory('$storage',['$window','$check',function($window,$check,$rootScope) {
	return {
		set: function(idx,val) { if(angular.isObject(val)) { $window.sessionStorage && $window.sessionStorage.setItem(idx,JSON.stringify(val)); return val; } else { $window.sessionStorage && $window.sessionStorage.setItem(idx,val); return val; }},
		setLocal: function(idx,val) { if(angular.isObject(val)) { $window.localStorage && $window.localStorage.setItem(idx,JSON.stringify(val)); return val; } else { $window.localStorage && $window.localStorage.setItem(idx,val); return val; }},
		get: function(idx) { if($check.json($window.sessionStorage.getItem(idx))) { var $object = JSON.parse($window.sessionStorage.getItem(idx)); return ($object === 'undefined') ? '' : $window.sessionStorage && $object; } else { return ($window.sessionStorage.getItem(idx) === 'undefined') ? '' : $window.sessionStorage && $window.sessionStorage.getItem(idx); }},
		getLocal: function(idx) { if($check.json($window.localStorage.getItem(idx))) { var $object = JSON.parse($window.localStorage.getItem(idx)); return ($object === 'undefined') ? '' : $window.localStorage && $object; } else { return ($window.localStorage.getItem(idx) === 'undefined') ? '' : $window.localStorage && $window.localStorage.getItem(idx); }},
		rm: function(idx) { $window.sessionStorage && $window.sessionStorage.removeItem(idx); return ''; },
		rmLocal: function(idx) { $window.localStorage && $window.localStorage.removeItem(idx); return ''; }
	};
}]);

app.factory('$appService',function($rootScope,$location,$route,$window,$interval,$storage,$translate,$http,amMoment,$q) {

	// # Instance

	var instance = {};

	// # Build application setup

	instance.do_setup = function(force) {

		// # Define async object

		var deferred = $q.defer(),
			promises = [];

		// # DATA - setup

		if(!$rootScope.setup || force) {
			promises.push($http.get('app/data/config.json').then(function(response) {
					
				// # Store data

				$rootScope.setup = response.status == 200 ? $storage.set('setup',response.data) : {};

			}));
		}

		// # DATA - routes

		if(!$rootScope.routes || force) {
			promises.push($http.get('app/data/routes.json').then(function(response) {
					
				// # Store data

				$rootScope.routes = response.status == 200 ? $storage.set('routes',response.data) : {};

			}));
		}

		// # Resolve
		
		if(promises) {			
			$q.all(promises).then(function() {
				deferred.resolve();
			},function() {
				deferred.resolve();
			});
		} else {
			deferred.resolve();
		}

		// # Return promise

		return deferred.promise;

	};

	// # Build application data

	instance.do_data = function(force) {

		// # Define async object

		var deferred = $q.defer(),
			promises = [];

		// # DATA - setup

		if(!$rootScope.i18n || force) {
			promises.push($http.get('app/data/i18n/' + $rootScope.setup.language + '.json').then(function(response) {
					
				// # Store data

				$rootScope.i18n = response.status == 200 ? $storage.set('i18n',response.data) : {};

			}));
		}

		// # Resolve
		
		if(promises) {			
			$q.all(promises).then(function() {

				// # Locale data

				amMoment.changeLocale($rootScope.setup.language);

				// # Load translation

				$translateProviderReference.translations($rootScope.setup.language,$rootScope.i18n);
				$translateProviderReference.preferredLanguage($rootScope.setup.language);
				$translate.use($rootScope.setup.language);

				// # Resolve

				deferred.resolve();

			},function() {
				deferred.resolve();
			});
		} else {
			deferred.resolve();
		}

		// # Return promise

		return deferred.promise;

	};

	// # Build route data

	instance.do_route = function() {

		// # Iterate routes

		angular.forEach($rootScope.routes,function(route) { 
			$routeProviderReference.when(route.url, {
				cache: false, 
				templateUrl: $rootScope.setup.template + (route.location || '') + route.template,
				controller: route.controller,
				data: route
			});
		});

		// # Define fallback

		$routeProviderReference.otherwise({redirectTo:'/404'});

		// # Reload routes

		$route.reload();

	};

	// # Set token

	instance.set_token = function() {
		$http.defaults.headers.common.Authorization = 'Bearer ' + $rootScope.token.token;
	};

	// # Remove token

	instance.remove_token = function() {
		delete $http.defaults.headers.common.Authorization;
	};

	// # Set session

	instance.set_session = function() {
		if($rootScope.user) { $rootScope.session = $interval(function() { $http.put('/api/session/ping',{},{ignoreLoadingBar:true});},60000); }
	};

	// # Remove session

	instance.remove_session = function() {
		if($rootScope.session) { $interval.cancel($rootScope.session); }
	};

	// # Notification

	instance.do_notify = function(type,message) {
		
		// # Switch type

		if(type == 'success') {
			var content = '<span uk-icon="icon:check"></span> <strong>' + $translate.instant('i18n_general_notify_success_title') + '</strong><p>' + $translate.instant(message || 'i18n_general_notify_success_default') + '</p>';
		} else if(type == 'error') {
			var content = '<span uk-icon="icon:close"></span> <strong>' + $translate.instant('i18n_general_notify_danger_title') + '</strong><p>' + $translate.instant(message || 'i18n_general_notify_danger_default') + '</p>';
		} else if(type == 'warning') {
			var content = '<span uk-icon="icon:warning"></span> <strong>' + $translate.instant('i18n_general_notify_warning_title') + '</strong><p>' + $translate.instant(message || 'i18n_general_notify_warning_default') + '</p>';
		} else if(type == 'info') {
			var content = '<span uk-icon="icon:info"></span> <strong>' + $translate.instant('i18n_general_notify_info_title') + '</strong><p>' + $translate.instant(message || 'i18n_general_notify_info_default') + '</p>';
		} else {
			var content = '';
		}

		UIkit.notification({
			message: content,
			status: type,
			pos: 'top-right',
			timeout: 5000
		});

	};

	// # Prepare api url

	instance.buildurl = function(data) {

		// # Define data

		var input = {
			url: data.url || null, reset: data.reset || false, id: data.id || null, filter: data.filter || null, filter_active: false, search: data.search || null, search_active: false, lastid: data.lastid || 0
		}; var origin = angular.copy(input);

		// # Build filter

		if(is.object(input.filter)) {
			var filter_tmp = ''; angular.forEach(input.filter,function(filter,index) {
				if(is.truthy(filter.value)) { input.url += is.empty(filter_tmp) ? '/filter/' + index + ':=:' + filter.value : ';' + index + ':=:' + filter.value; input.filter_active = true; filter_tmp = input.url; }
			});
		}

		// # Build search

		if(is.object(input.search)) {
			if(is.truthy(input.search.value)) { input.url += '/search/' + input.search.field + ':' + input.search.value; input.search_active = true; }
		}
		
		// # Build lastid 

		if(is.truthy(input.lastid) && input.lastid != 0) {
			input.url += (!input.filter_active && !input.search_active) ? '/0/' + input.lastid : '/' + input.lastid;
		}

		// # Catch reset

		if(is.truthy(input.reset)) {
			input.url = origin.url;
		}

		// # Catch id

		if(is.truthy(input.id)) {
			input.url = origin.url + '/' + input.id;
		}

		// # Return string

		return input;

	};

	// # Return

	return instance;

});
app.controller('appCtrl',function($scope,$rootScope,$location,$translate,$route,$routeParams,$storage,$interval,$filter,$http,$ngConfirm,$appService) {

	// # Predefine data

	$scope.data = {};
	$scope.form = {newpassword:{}};
	$scope.logindata = {username:$storage.getLocal('username'),password:$storage.getLocal('password'),remember:$storage.getLocal('remember')};

	// # Login

	$scope.login = function() {

		// # Reset errors and set loading state

		$scope.form.login.load = true;
		$scope.form.login.error = false;

		// # Api call

		$http.post('/api/authenticate',{
			username: $scope.logindata.username,
			password: $scope.logindata.password
		}).then(function(response) {

			// # Reset loading state
			
			$scope.form.login.load = false;
			
			// # Store token

			$rootScope.token = $storage.set('token',response.data); $appService.set_token();

			// # Fetch user data

			$http.get('/api/user/' + response.data.user).then(function(response) {

				// # Set data

				$rootScope.user = $storage.set('user',response.data);

				// # Register session interval

				$appService.set_session();

				// # Store remember

				if($scope.logindata.remember) {
					$storage.setLocal('username',$scope.logindata.username);
					$storage.setLocal('password',$scope.logindata.password);
					$storage.setLocal('remember',$scope.logindata.remember);
					
				} else {
					$scope.logindata = {
						username: $storage.rmLocal('username'),
						password: $storage.rmLocal('password'),
						remember: $storage.rmLocal('remember')
					};
				}

				// # Redirect

				$location.reload();

			});

		},function(response) {

			// # Set error and loading state

			$scope.form.login.load = false;
			$scope.form.login.error = true;

		});

	};

	// # Logout

	$scope.logout = function() {
		$ngConfirm({
			theme: 'supervan',
			useBootstrap: false,
			title: $translate.instant('i18n_login_logout_confirm_title'),
			content: $translate.instant('i18n_login_logout_confirm_description'),
			scope: $scope,
			buttons: {
				confirm: {
					text: $translate.instant('i18n_login_logout_confirm_do'),
					btnClass: 'uk-button uk-button-primary',
					action: function(scope,button) {
						scope.$apply(function() {

							// # Update data

							$rootScope.user = $storage.rm('user');
							$rootScope.token = $storage.rm('token'); $appService.remove_token();
							$appService.remove_session();

							// # Redirect

							$location.path('/');

						});
					}
				},
				abort: {
					text: $translate.instant('i18n_login_logout_confirm_abort'),
					btnClass: 'uk-button uk-button-default'
				}
			}
		});
	};

	$scope.newpassword_submit = function(data) {

		// # Set loading state

		$scope.form.newpassword.load = true;

		// # Api call

		$http.put('/api/user/' + $rootScope.user.uid,{
			password: 			data.password,
			password_confirm: 	data.password_confirm
		}).then(function(response) {

			// # Reset loading state
			
			$scope.form.newpassword.load = false;
			
			// # Notify

			$appService.do_notify('success');

			// # Reset values and hide modal

			data.password = null; data.password_confirm = null;
			UIkit.modal('#modal-newpassword').hide();

		},function(response) {

			// # Set error and loading state

			$scope.form.newpassword.load = false;

			// # Evaluate errors

			if(response.data.errors) {
				angular.forEach(response.data.errors,function(error) {
					$scope.form.newpassword[error.column].$setValidity('invalid',false);
				});
			}
		});
	};
});

app.controller('accountCtrl',function($scope,$rootScope,$http,$filter,$route,$location,$routeParams,$appService) {

	// # Predefine forms

	$scope.form = {};

	// # Predefine data

	$http.get($rootScope.setup.template + 'modules/account/config.json',{cache:false}).then(function(response) {
		if(response.status == 200) {

			// # Store configuration

			$scope.data = response.data;

			// # DEBUG

			if($rootScope.setup.mode == 'sandbox') { console.log('Controller (' + $rootScope.route.controller + ') registered'); }

			// # Fetch subset data

			angular.forEach($scope.data.subsets,function(subset,index) {
				$http.get(subset.url,{cache:false}).then(function(response) {
					$scope.data.subsets[index].results = response.status == 200 ? response.data || [] : [];
				});
			});

			// # Fetch data

			$scope.fetch = function(reset,paging) {

				// # Define url builder

				var buildurl = $appService.buildurl({url:$scope.data.url,id:$rootScope.user.uid});

				// # Do Request

				$http.get(buildurl.url,{cache:false}).then(function(response) {
					$scope.data.result = response.status == 200 ? response.data || {} : {};
				});

			}; $scope.fetch(true);

			// # Define save functions

			$scope.save = function() {

				// # Reset errors and set loading state

				$scope.form.main.load = true;
				$scope.form.main.error = false;

				// # Call api

				$http.put($scope.data.url + '/' + $scope.data.result.uid,{
					company:  				$scope.data.result.company,
					company_department:  	$scope.data.result.company_department,
					company_extra:  		$scope.data.result.company_extra,
					forename:  				$scope.data.result.forename,
					surname:  				$scope.data.result.surname,
					address:  				$scope.data.result.address,
					address_number:  		$scope.data.result.address_number,
					address_extra:  		$scope.data.result.address_extra,
					zipcode:  				$scope.data.result.zipcode,
					city:  					$scope.data.result.city,
					country:  				$scope.data.result.country,
					email:  				$scope.data.result.email,
					phone:  				$scope.data.result.phone,
					phone_mobile:  			$scope.data.result.phone_mobile,
					fax:  					$scope.data.result.fax
				}).then(function(response) {

					// # Reset loading state

					$scope.form.main.load = false;

					// # Notify

					$appService.do_notify('success');

					// # Update scope 

					if(response.status == 200) {
						$scope.data.result = angular.copy(response.data); 
					}

				},function(response) {

					// # Set error and loading state

					$scope.form.main.load = false;

					// # Evaluate errors

					if(response.data.errors) {
						$appService.do_notify('error'); angular.forEach(response.data.errors,function(error) {
							$scope.form.main[error.column].$setValidity('invalid',false);
						});
					}

				});
			};

			// # Validate functions

			$scope.validate_synonym = function() {
				$http.get('/api/user/validate/synonym/' + $scope.data.account.synonym).then(function(response) {
					$scope.form.account.synonym.$setValidity('exist',true);
				},function(response) {
					$scope.form.account.synonym.$setValidity('exist',false);
				});
			};

			$scope.validate_email = function() {
				$http.get('/api/user/validate/email/' + $scope.data.account.email).then(function(response) {
					$scope.form.account.email.$setValidity('exist',true);
				},function(response) {
					$scope.form.account.email.$setValidity('exist',false);
				});
			};

		}
	},function() {
		console.error('Controller (' + $rootScope.route.controller + ') failed to fetch module configuration');
	}); 
});

app.controller('objectCtrl',function($scope,$rootScope,$http,$filter,$route,$location,$translate,$routeParams,$appService) {

	// # Predefine forms

	$scope.form = {};

	// # Predefine data

	$http.get($rootScope.setup.template + 'modules/object/config.json',{cache:false}).then(function(response) {
		if(response.status == 200) {

			// # Store configuration

			$scope.data = response.data;

			// # DEBUG

			if($rootScope.setup.mode == 'sandbox') { console.log('Controller (' + $rootScope.route.controller + ') registered'); }

			// # Fetch subset data

			angular.forEach($scope.data.subsets,function(subset,index) {
				$http.get(subset.url,{cache:false}).then(function(response) {
					$scope.data.subsets[index].results = response.status == 200 ? response.data || [] : [];
				});
			});

			// # Fetch data

			$scope.fetch = function(reset,paging) {
				if($routeParams && $routeParams.uid && $routeParams.uid != 'create') {

					// # Define url builder

					var buildurl = $appService.buildurl({url:$scope.data.url,id:$routeParams.uid});

					// # Do Request

					$http.get(buildurl.url,{cache:false}).then(function(response) {

						// # Store data

						$scope.data.result = response.status == 200 ? response.data || {} : {};

						// # Date fields

						$scope.data.result.warranty = $scope.data.result.warranty ? moment($scope.data.result.warranty) : null;

					});

				} else if(!$routeParams || !$routeParams.uid) {

					// # Define url builder

					var buildurl = $appService.buildurl({url:$scope.data.url,lastid:$scope.data.config.lastid,filter:$scope.data.filter,search:$scope.data.search,reset:reset});
					
					// # Set active states

					$scope.data.filter.active = buildurl.filter_active;
					$scope.data.search.active = buildurl.search_active;

					// # Do Request

					$http.get(buildurl.url,{cache:false}).then(function(response) {
						if(paging) {
							angular.forEach(response.data,function(datum) { $scope.data.results.push(datum); });
						} else {
							$scope.data.results = response.status == 200 ? response.data || [] : []; 
						} if(is.array(response.data)) { $scope.data.config.lastid = response.data.slice(-1).pop().id; }
					});
					
				}
			}; $scope.fetch(true);

			// # Define filter functions

			$scope.filter_do = function() {
				$scope.data.config.lastid = 0; $scope.fetch();
			};

			$scope.filter_clear = function() {
				angular.forEach($scope.data.filter,function(filter,index) {
					$scope.data.filter[index].value = null;
				}); $scope.data.config.lastid = 0; $scope.data.filter.active = false; $scope.fetch(true);
			};

			// # Define search functions

			$scope.search_do = function() {
				$scope.data.config.lastid = 0; $scope.fetch();
			};

			$scope.search_clear = function() {
				$scope.data.search.value = null; $scope.data.config.lastid = 0; $scope.data.search.active = false; $scope.fetch(true);
			};

			// # Define paging functions
			
			$scope.table_paging = function(page) {
				if(page == ($scope.data.results.length / $scope.data.config.limit)) {
					$scope.fetch(false,true); 
				}
			}; 

			$scope.table_reorder = function() { 
				$scope.data.config.page = 1; 
			};

			// # Define save functions

			$scope.save = function() {

				// # Reset errors and set loading state

				$scope.form.main.load = true;
				$scope.form.main.error = false;

				// # Call api
				
				if($scope.data.result.uid) {
					$http.put($scope.data.url + '/' + $scope.data.result.uid,{
						region: 			$scope.data.result.region,
						name:  				$scope.data.result.name,
						address:  			$scope.data.result.address,
						address_number:  	$scope.data.result.address_number,
						address_extra:  	$scope.data.result.address_extra,
						zipcode:  			$scope.data.result.zipcode,
						city:  				$scope.data.result.city,
						country:  			$scope.data.result.country,
						warranty: 			($scope.data.result.warranty ? moment($scope.data.result.warranty,'DD.MM.YYYY').format('YYYY-MM-DD') : null),
						emergencies: 		($scope.data.result.emergencies_s ? $scope.data.result.emergencies_s.join(',') : null || null),
						notice: 			$scope.data.result.notice
					}).then(function(response) {

						// # Reset loading state

						$scope.form.main.load = false;

						// # Notify

						$appService.do_notify('success');

						// # Update scope 

						if(response.status == 200) {
							$scope.fetch(true); 
						}

					},function(response) {

						// # Set error and loading state

						$scope.form.main.load = false;

						// # Evaluate errors

						if(response.data.errors) {
							$appService.do_notify('error'); angular.forEach(response.data.errors,function(error) {
								$scope.form.main[error.column].$setValidity('invalid',false);
							});
						}

					});
				} else {
					$http.post($scope.data.url,{
						region: 			$scope.data.result.region,
						name:  				$scope.data.result.name,
						address:  			$scope.data.result.address,
						address_number:  	$scope.data.result.address_number,
						address_extra:  	$scope.data.result.address_extra,
						zipcode:  			$scope.data.result.zipcode,
						city:  				$scope.data.result.city,
						country:  			$scope.data.result.country,
						warranty: 			($scope.data.result.warranty ? moment($scope.data.result.warranty,'DD.MM.YYYY').format('YYYY-MM-DD') : null),
						emergencies: 		($scope.data.result.emergencies_s ? $scope.data.result.emergencies_s.join(',') : null || null),
						notice: 			$scope.data.result.notice
					}).then(function(response) {

						// # Reset loading state

						$scope.form.main.load = false;

						// # Notify

						$appService.do_notify('success');

						// # Redirect

						if(response.status == 200) {
							$location.path($rootScope.route.url.replace(':uid',response.data.uid));
						}

					},function(response) {

						// # Set error and loading state

						$scope.form.main.load = false;

						// # Evaluate errors

						if(response.data.errors) {
							$appService.do_notify('error'); angular.forEach(response.data.errors,function(error) {
								$scope.form.main[error.column].$setValidity('invalid',false);
							});
						}

					});
				}
			};

			// # Define copy function

			$scope.copy = function(data) {
				if(confirm($translate.instant('i18n_general_copy_confirm'))) {

					// # Call api
					
					$http.post($scope.data.url + '/' + data.uid,{}).then(function(response) {

						// # Notify

						$appService.do_notify('success');

						// # Redirect

						if(response.status == 200) {
							$scope.data.results.push(response.data);
						}

					},function(response) {

						// # Evaluate errors

						if(response.data.errors) {
							$appService.do_notify('error'); angular.forEach(response.data.errors,function(error) {
								$scope.form.main[error.column].$setValidity('invalid',false);
							});
						}

					});
				}
			};

			// # Define delete function

			$scope.delete = function(data) {
				if(confirm($translate.instant('i18n_general_delete_confirm'))) {

					// # Call api
					
					$http.delete($scope.data.url + '/' + data.uid).then(function(response) {

						// # Notify

						$appService.do_notify('success');

						// # Renew data

						if(response.status == 200) {
							$scope.fetch(true);
						}

					},function(response) {

						// # Evaluate errors

						if(response.data.errors) {
							$appService.do_notify('error'); angular.forEach(response.data.errors,function(error) {
								$scope.form.main[error.column].$setValidity('invalid',false);
							});
						}

					});
				}
			};

		}
	},function() {
		console.error('Controller (' + $rootScope.route.controller + ') failed to fetch module configuration');
	}); 
});

app.controller('orderCtrl',function($scope,$rootScope,$http,$filter,$route,$location,$timeout,$routeParams,$appService,$ngConfirm,$translate) {

	// # Predefine forms

	$scope.form = {};

	// # Predefine data

	$http.get($rootScope.setup.template + 'modules/order/config.json',{cache:false}).then(function(response) {
		if(response.status == 200) {

			// # Store configuration

			$scope.data = response.data;

			// # DEBUG

			if($rootScope.setup.mode == 'sandbox') { console.log('Controller (' + $rootScope.route.controller + ') registered'); }

			// # Fetch subset data

			angular.forEach($scope.data.subsets,function(subset,index) {
				$http.get(subset.url,{cache:false}).then(function(response) {
					$scope.data.subsets[index].results = response.status == 200 ? response.data || [] : [];
				});
			});

			// # Fetch data

			$scope.fetch = function(reset,paging) {
				if($routeParams && $routeParams.uid && $routeParams.uid != 'create') {

					// # Define url builder

					var buildurl = $appService.buildurl({url:$scope.data.url,id:$routeParams.uid});

					// # Do Request

					$http.get(buildurl.url,{cache:false}).then(function(response) {
						
						// # Store data

						$scope.data.result = response.status == 200 ? response.data || {} : {};

						// # Date fields

						$scope.data.result.execute = $scope.data.result.execute ? moment($scope.data.result.execute) : null;

					});

				} else if($routeParams && $routeParams.uid && $routeParams.uid == 'create') {

					// # Predefine data

					$scope.data.result = {};

					// # Initial data

					$timeout(function() {
						$scope.data.result.reminder = moment().add(4,'days');
					});

				} else if(!$routeParams.uid) {

					// # Define url builder

					var buildurl = $appService.buildurl({url:$scope.data.url,lastid:$scope.data.config.lastid,filter:$scope.data.filter,search:$scope.data.search,reset:reset});
					
					// # Set active states

					$scope.data.filter.active = buildurl.filter_active;
					$scope.data.search.active = buildurl.search_active;

					// # Do Request

					$http.get(buildurl.url,{cache:false}).then(function(response) {
						if(paging) {
							angular.forEach(response.data,function(datum) { $scope.data.results.push(datum); });
						} else {
							$scope.data.results = response.status == 200 ? response.data || [] : []; 
						} if(is.array(response.data)) { $scope.data.config.lastid = response.data.slice(-1).pop().id; }
					});
					
				}
			}; $scope.fetch(true);

			// # Define filter functions

			$scope.filter_do = function() {
				$scope.data.config.lastid = 0; $scope.fetch();
			};

			$scope.filter_clear = function() {
				angular.forEach($scope.data.filter,function(filter,index) {
					$scope.data.filter[index].value = null;
				}); $scope.data.config.lastid = 0; $scope.data.filter.active = false; $scope.fetch(true);
			};

			// # Define search functions

			$scope.search_do = function() {
				$scope.data.config.lastid = 0; $scope.fetch();
			};

			$scope.search_clear = function() {
				$scope.data.search.value = null; $scope.data.config.lastid = 0; $scope.data.search.active = false; $scope.fetch(true);
			};

			// # Define paging functions
			
			$scope.table_paging = function(page) {
				if(page == ($scope.data.results.length / $scope.data.config.limit)) {
					$scope.fetch(false,true); 
				}
			}; 

			$scope.table_reorder = function() { 
				$scope.data.config.page = 1; 
			};

			// # Define save functions

			$scope.save = function() {

				// # Reset errors and set loading state

				$scope.form.main.load = true;
				$scope.form.main.error = false;

				// # Call api
				
				if($scope.data.result.uid) {
					$http.put($scope.data.url + '/' + $scope.data.result.uid,{
						ticket:  		$scope.data.result.ticket,
						ticket_info: 	$scope.data.result.ticket_info,
						user:  			$scope.data.result.user,
						execute:  		($scope.data.result.execute ? moment($scope.data.result.execute,'DD.MM.YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss') : null),
						description:  	$scope.data.result.description,
						activity:  		$scope.data.result.activity,
						medias: 		($scope.data.result.medias_a ? $scope.data.result.medias_a.map(function(media) { return media.id; }).join(',') : null)
					}).then(function(response) {

						// # Reset loading state

						$scope.form.main.load = false;

						// # Notify

						$appService.do_notify('success');

						// # Update scope 

						if(response.status == 200) {
							$scope.data.result = angular.copy(response.data); 
							$scope.data.result.execute = $scope.data.result.execute ? moment($scope.data.result.execute) : null;
						}

					},function(response) {

						// # Set error and loading state

						$scope.form.main.load = false;

						// # Evaluate errors

						if(response.data.errors) {
							$appService.do_notify('error'); angular.forEach(response.data.errors,function(error) {
								$scope.form.main[error.column].$setValidity('invalid',false);
							});
						}

					});
				} else {
					$http.post($scope.data.url,{
						ticket:  				($rootScope.route.params.ticket ? $rootScope.route.params.ticket : $scope.data.result.ticket),
						ticket_info: 			$scope.data.result.ticket_info,
						object:  				($rootScope.route.params.object ? $rootScope.route.params.object : $scope.data.result.object),
						user:  					$scope.data.result.user,
						execute:  				($scope.data.result.execute ? moment($scope.data.result.execute,'DD.MM.YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss') : null),
						execute_confirm: 		($scope.data.result.execute_confirm == 1 ? moment().format('YYYY-MM-DD HH:mm:ss') : null),
						execute_confirm_user: 	($scope.data.result.execute_confirm == 1 ? $rootScope.user.id : null),
						description:  			$scope.data.result.description,
						medias: 				($scope.data.result.medias_a ? $scope.data.result.medias_a.map(function(media) { return media.id; }).join(',') : null)
					}).then(function(response) {

						// # Reset loading state

						$scope.form.main.load = false;

						// # Notify

						$appService.do_notify('success');

						// # Redirect

						if(response.status == 200) {
							$location.path($rootScope.route.url.replace(':uid',response.data.uid));
						}

					},function(response) {

						// # Set error and loading state

						$scope.form.main.load = false;

						// # Evaluate errors

						if(response.data.errors) {
							$appService.do_notify('error'); angular.forEach(response.data.errors,function(error) {
								$scope.form.main[error.column].$setValidity('invalid',false);
							});
						}

					});
				}
			};

			// # Define comment save functions

			$scope.save_comment = function(comment) {

				// # Reset errors and set loading state

				$scope.form.comment.load = true;
				$scope.form.comment.error = false;

				// # Call api
				
				if(comment.uid) {
					$http.put('/api/comment/' + comment.uid,{
						strike: comment.strike
					}).then(function(response) {

						// # Reset loading state

						$scope.form.comment.load = false;

						// # Update scope 

						if(response.status == 200) {
							comment = angular.copy(response.data); 
						}

					},function(response) {

						// # Set error and loading state

						$scope.form.comment.load = false;

						// # Evaluate errors

						if(response.data.errors) {
							$appService.do_notify('error'); angular.forEach(response.data.errors,function(error) {
								$scope.form.comment[error.column].$setValidity('invalid',false);
							});
						}

					});
				} else {
					$http.post('/api/comment',{
						target:  	$scope.data.result.uid,
						value:  	comment.value
					}).then(function(response) {

						// # Reset loading state

						$scope.form.comment.load = false;

						// # Reset values

						comment.value = null;

						// # Redirect

						if(response.status == 200) {
							$scope.data.result.comments.push(response.data);
						}

					},function(response) {

						// # Set error and loading state

						$scope.form.comment.load = false;

						// # Evaluate errors

						if(response.data.errors) {
							$appService.do_notify('error'); angular.forEach(response.data.errors,function(error) {
								$scope.form.comment[error.column].$setValidity('invalid',false);
							});
						}

					});
				}
			};

			// # Define media functions

			$scope.delete_media = function(media) {
				$http.delete('/api/media/' + media.uid).then(function(response) {
					if(response.status == 200) {
						$scope.data.result.medias_a.splice($scope.data.result.medias_a.indexOf(media),1);
					}
				},function(response) {
					$appService.do_notify('danger');
				});
			};

			// # Define confirm order function

			$scope.confirm_order = function() {
				$ngConfirm({
					theme: 'supervan',
					useBootstrap: false,
					title: $translate.instant('i18n_order_confirm_title'),
					content: $translate.instant('i18n_order_confirm_description'),
					scope: $scope,
					buttons: {
						confirm: {
							text: $translate.instant('i18n_order_confirm_do'),
							btnClass: 'uk-button uk-button-primary',
							action: function(scope,button) {

								// # Do api call

								$http.put($scope.data.url + '/' + $scope.data.result.uid,{
									execute_confirm: moment().format('YYYY-MM-DD HH:mm:ss'),
									execute_confirm_user: $rootScope.user.id
								}).then(function(response) {

									// # Reset loading state

									$scope.form.main.load = false;

									// # Evaluate state

									if(response.status == 200) {

										// # Notify

										$appService.do_notify('success','i18n_order_confirm_success');

										// # Update data

										$scope.data.result = angular.copy(response.data);

									}
								},function(response) {

									// # Set error and loading state

									$scope.form.main.load = false;

									// # Evaluate errors

									if(response.data.errors) {
										$appService.do_notify('error'); angular.forEach(response.data.errors,function(error) {
											$scope.form.main[error.column].$setValidity('invalid',false);
										});
									}
								});
							}
						},
						abort: {
							text: $translate.instant('i18n_order_confirm_abort'),
							btnClass: 'uk-button uk-button-default'
						}
					}
				});
			};

			// # Define confirm order rollback function

			$scope.confirm_order_rollback = function() {
				$ngConfirm({
					theme: 'supervan',
					useBootstrap: false,
					title: $translate.instant('i18n_order_confirm_title'),
					content: $translate.instant('i18n_order_confirm_rollback_description'),
					scope: $scope,
					buttons: {
						confirm: {
							text: $translate.instant('i18n_order_confirm_rollback_do'),
							btnClass: 'uk-button uk-button-primary',
							action: function(scope,button) {

								// # Do api call

								$http.put($scope.data.url + '/' + $scope.data.result.uid,{
									execute_confirm: null,
									execute_confirm_user: null
								}).then(function(response) {

									// # Reset loading state

									$scope.form.main.load = false;

									// # Evaluate state

									if(response.status == 200) {

										// # Notify

										$appService.do_notify('success','i18n_order_confirm_rollback_success');

										// # Update data

										$scope.data.result = angular.copy(response.data);

									}
								},function(response) {

									// # Set error and loading state

									$scope.form.main.load = false;

									// # Evaluate errors

									if(response.data.errors) {
										$appService.do_notify('error'); angular.forEach(response.data.errors,function(error) {
											$scope.form.main[error.column].$setValidity('invalid',false);
										});
									}
								});
							}
						},
						abort: {
							text: $translate.instant('i18n_order_confirm_abort'),
							btnClass: 'uk-button uk-button-default'
						}
					}
				});
			};

			// # Define complete order function

			$scope.complete_order = function() {
				$ngConfirm({
					theme: 'supervan',
					useBootstrap: false,
					title: $translate.instant('i18n_order_complete_confirm_title'),
					content: $translate.instant('i18n_order_complete_confirm_description'),
					scope: $scope,
					buttons: {
						confirm: {
							text: $translate.instant('i18n_order_complete_confirm_do'),
							btnClass: 'uk-button uk-button-primary',
							action: function(scope,button) {

								// # Do api call

								$http.put($scope.data.url + '/' + $scope.data.result.uid,{
									activity: $filter('filter')($scope.data.subsets.activities.results,{key:'complete'})[0].id
								}).then(function(response) {

									// # Reset loading state

									$scope.form.main.load = false;

									// # Evaluate state

									if(response.status == 200) {

										// # Notify

										$appService.do_notify('success','i18n_order_complete_confirm_success');

										// # Update data

										$scope.data.result = angular.copy(response.data);

									}
								},function(response) {

									// # Set error and loading state

									$scope.form.main.load = false;

									// # Evaluate errors

									if(response.data.errors) {
										$appService.do_notify('error'); angular.forEach(response.data.errors,function(error) {
											$scope.form.main[error.column].$setValidity('invalid',false);
										});
									}
								});
							}
						},
						abort: {
							text: $translate.instant('i18n_order_complete_confirm_abort'),
							btnClass: 'uk-button uk-button-default'
						}
					}
				});
			};

			// # Define uploader

			var bar = document.getElementById('mbh-progressbar'); UIkit.upload('.mbh-upload', {

				url: '/api/media',
				multiple: true,
				allow: '*.jpg|*.jpeg|*.gif|*.png|*.tif|*.tiff|*.pdf|*.zip|*.doc|*.docx',
		
				beforeSend: function(xhr) { 
					xhr.headers = {Authorization: 'Bearer ' + $rootScope.token.token}					
				},
				error: function() {
					$appService.do_notify('error','i18n_error_upload_size');
				},
				fail: function() {
					$appService.do_notify('error','i18n_error_upload_type');
				},
				loadStart: function (e) {
					bar.removeAttribute('hidden'); bar.max = e.total; bar.value = e.loaded;
				},
				progress: function (e) {
					bar.max = e.total; bar.value = e.loaded;
				},
				loadEnd: function (e) {
					bar.max = e.total; bar.value = e.loaded;
				},
				complete: function(response) {
					if(response.status == 200) {
						angular.forEach(JSON.parse(response.response),function(media) {
							$scope.$apply(function() { if(!$scope.data.result.medias_a) { $scope.data.result.medias_a = []; } $scope.data.result.medias_a.push(media); });
						});
					} else {
						var json = JSON.parse(response.response); $appService.do_notify('danger',json.code);
					}
				},
				completeAll: function(response) {
					setTimeout(function () {
						bar.setAttribute('hidden', 'hidden');
					},1000);
				}
			});

		}
	},function() {
		console.error('Controller (' + $rootScope.route.controller + ') failed to fetch module configuration');
	}); 
});

app.controller('ticketCtrl',function($scope,$rootScope,$http,$filter,$route,$location,$routeParams,$appService) {

	// # Predefine forms

	$scope.form = {};

	// # Predefine data

	$http.get($rootScope.setup.template + 'modules/ticket/config.json',{cache:false}).then(function(response) {
		if(response.status == 200) {

			// # Store configuration

			$scope.data = response.data;

			// # DEBUG

			if($rootScope.setup.mode == 'sandbox') { console.log('Controller (' + $rootScope.route.controller + ') registered'); }

			// # Fetch subset data

			angular.forEach($scope.data.subsets,function(subset,index) {
				$http.get(subset.url,{cache:false}).then(function(response) {

					// # Store data

					$scope.data.subsets[index].results = response.status == 200 ? response.data || [] : [];

					// # Preselections

					if(index == 'objects') {
						if($scope.data.subsets[index].results.length == 1) {
							$scope.data.result.object = $scope.data.subsets[index].results[0].id;
						}
					}
				});
			});

			// # Fetch data

			$scope.fetch = function(reset,paging) {
				if($routeParams && $routeParams.uid && $routeParams.uid != 'create') {
					
					// # Define url builder

					var buildurl = $appService.buildurl({url:$scope.data.url,id:$routeParams.uid});

					// # Do Request

					$http.get(buildurl.url,{cache:false}).then(function(response) {
						$scope.data.result = response.status == 200 ? response.data || {} : {};
					});

				} else if(!$routeParams.uid) {

					// # Define url builder

					var buildurl = $appService.buildurl({url:$scope.data.url,lastid:$scope.data.config.lastid,filter:$scope.data.filter,search:$scope.data.search,reset:reset});
					
					// # Set active states

					$scope.data.filter.active = buildurl.filter_active;
					$scope.data.search.active = buildurl.search_active;

					// # Do Request

					$http.get(buildurl.url,{cache:false}).then(function(response) {
						if(paging) {
							angular.forEach(response.data,function(datum) { $scope.data.results.push(datum); });
						} else {
							$scope.data.results = response.status == 200 ? response.data || [] : []; 
						} if(is.array(response.data)) { $scope.data.config.lastid = response.data.slice(-1).pop().id; }
					});

				}
			}; $scope.fetch(true);

			// # Define filter functions

			$scope.filter_do = function() {
				$scope.data.config.lastid = 0; $scope.fetch();
			};

			$scope.filter_clear = function() {
				angular.forEach($scope.data.filter,function(filter,index) {
					$scope.data.filter[index].value = null;
				}); $scope.data.config.lastid = 0; $scope.data.filter.active = false; $scope.fetch(true);
			};

			// # Define search functions

			$scope.search_do = function() {
				$scope.data.config.lastid = 0; $scope.fetch();
			};

			$scope.search_clear = function() {
				$scope.data.search.value = null; $scope.data.config.lastid = 0; $scope.data.search.active = false; $scope.fetch(true);
			};

			// # Define paging functions
			
			$scope.table_paging = function(page) {
				if(page == ($scope.data.results.length / $scope.data.config.limit)) {
					$scope.fetch(false,true); 
				}
			}; 

			$scope.table_reorder = function() { 
				$scope.data.config.page = 1; 
			};

			// # Define save functions

			$scope.save = function() {

				// # Reset errors and set loading state

				$scope.form.main.load = true;
				$scope.form.main.error = false;

				// # Call api
				
				if($scope.data.result.uid) {
					$http.put($scope.data.url + '/' + $scope.data.result.uid,{
						type: 			$scope.data.result.type,
						object:  		$scope.data.result.object,
						subject:  		$scope.data.result.subject,
						description:  	$scope.data.result.description,
						medias: 		($scope.data.result.medias_a ? $scope.data.result.medias_a.map(function(media) { return media.id; }).join(',') : null),
						activity: 		$scope.data.result.activity
					}).then(function(response) {

						// # Reset loading state

						$scope.form.main.load = false;

						// # Notify

						$appService.do_notify('success');

						// # Update scope 

						if(response.status == 200) {
							$scope.data.result = angular.copy(response.data); 
						}

					},function(response) {

						// # Set error and loading state

						$scope.form.main.load = false;

						// # Evaluate errors

						if(response.data.errors) {
							angular.forEach(response.data.errors,function(error) {
								$scope.form.main[error.column].$setValidity('invalid',false);
							});
						}

					});
				} else {
					$http.post($scope.data.url,{
						type: 			$scope.data.result.type,
						object:  		$scope.data.result.object,
						subject:  		$scope.data.result.subject,
						description:  	$scope.data.result.description,
						medias: 		($scope.data.result.medias_a ? $scope.data.result.medias_a.map(function(media) { return media.id; }).join(',') : null),
						activity: 		$scope.data.result.activity
					}).then(function(response) {

						// # Reset loading state

						$scope.form.main.load = false;

						// # Notify

						$appService.do_notify('success');

						// # Redirect

						if(response.status == 200) {
							$location.path($rootScope.route.url.replace(':uid',''));
						}

					},function(response) {

						// # Set error and loading state

						$scope.form.main.load = false;

						// # Evaluate errors

						if(response.data.errors) {
							angular.forEach(response.data.errors,function(error) {
								$scope.form.main[error.column].$setValidity('invalid',false);
							});
						}

					});
				}
			};

			$scope.save_rate = function() {

				// # Reset errors and set loading state

				$scope.form.rate.load = true;
				$scope.form.rate.error = false;

				// # Call api
				
				$http.post($scope.data.url + '/rate/' + $scope.data.result.uid,{
					value:  		$scope.rate_value,
					description:  	$scope.rate_description
				}).then(function(response) {

					// # Reset loading state

					$scope.form.rate.load = false;

					// # Notify

					$appService.do_notify('success','i18n_ticket_notify_rating_success');

					// # Redirect

					if(response.status == 200) {
						$location.path($rootScope.route.url.replace('/:uid/rate',''));
					}

				},function(response) {

					// # Set error and loading state

					$scope.form.rate.load = false;

					// # Evaluate errors

					if(response.data.errors) {
						angular.forEach(response.data.errors,function(error) {
							$scope.form.rate[error.column].$setValidity('invalid',false);
						});
					}

				});
			};

			// # Define media functions

			$scope.delete_media = function(media) {
				$http.delete('/api/media/' + media.uid).then(function(response) {
					if(response.status == 200) {
						$scope.data.result.medias_a.splice($scope.data.result.medias_a.indexOf(media),1);
					}
				},function(response) {
					$appService.do_notify('danger');
				});
			};

			// # Define uploader

			var bar = document.getElementById('mbh-progressbar'); UIkit.upload('.mbh-upload', {

				url: '/api/media',
				multiple: true,
				allow: '*.jpg|*.jpeg|*.gif|*.png|*.tif|*.tiff|*.pdf|*.zip|*.doc|*.docx',
		
				beforeSend: function(xhr) { 
					xhr.headers = {Authorization: 'Bearer ' + $rootScope.token.token}					
				},
				error: function() {
					$appService.do_notify('error','i18n_error_upload_size');
				},
				fail: function() {
					$appService.do_notify('error','i18n_error_upload_type');
				},
				loadStart: function (e) {
					bar.removeAttribute('hidden'); bar.max = e.total; bar.value = e.loaded;
				},
				progress: function (e) {
					bar.max = e.total; bar.value = e.loaded;
				},
				loadEnd: function (e) {
					bar.max = e.total; bar.value = e.loaded;
				},
				complete: function(response) {
					if(response.status == 200) {
						angular.forEach(JSON.parse(response.response),function(media) {
							$scope.$apply(function() { if(!$scope.data.result.medias_a) { $scope.data.result.medias_a = []; } $scope.data.result.medias_a.push(media); });
						});
					} else {
						$appService.do_notify('danger','i18n_error_upload_size');
					}
				},
				completeAll: function(response) {
					setTimeout(function () {
						bar.setAttribute('hidden', 'hidden');
					},1000);
				}
			});

			// # Catch revert from ticket

			if($rootScope.route_last && $rootScope.route_last.key == 'order') {
				$scope.data.view = 1;
			}

		}
	},function() {
		console.error('Controller (' + $rootScope.route.controller + ') failed to fetch module configuration');
	}); 
});

app.controller('userCtrl',function($scope,$rootScope,$http,$filter,$translate,$route,$location,$routeParams,$appService) {

	// # Predefine forms

	$scope.form = {};

	// # Predefine data

	$http.get($rootScope.setup.template + 'modules/user/config.json',{cache:false}).then(function(response) {
		if(response.status == 200) {

			// # Store configuration

			$scope.data = response.data;

			// # DEBUG

			if($rootScope.setup.mode == 'sandbox') { console.log('Controller (' + $rootScope.route.controller + ') registered'); }

			// # Fetch subset data

			angular.forEach($scope.data.subsets,function(subset,index) {
				$http.get(subset.url,{cache:false}).then(function(response) {
					$scope.data.subsets[index].results = response.status == 200 ? response.data || [] : [];
				});
			});

			// # Fetch data

			$scope.fetch = function(reset,paging) {
				if($routeParams && $routeParams.uid && $routeParams.uid != 'create') {

					// # Define url builder

					var buildurl = $appService.buildurl({url:$scope.data.url,id:$routeParams.uid});

					// # Do Request

					$http.get(buildurl.url,{cache:false}).then(function(response) {
						$scope.data.result = response.status == 200 ? response.data || {} : {};
					});

				} else if(!$routeParams.uid) {

					// # Define url builder

					var buildurl = $appService.buildurl({url:$scope.data.url,lastid:$scope.data.config.lastid,filter:$scope.data.filter,search:$scope.data.search,reset:reset});
					
					// # Set active states

					$scope.data.filter.active = buildurl.filter_active;
					$scope.data.search.active = buildurl.search_active;

					// # Do Request

					$http.get(buildurl.url,{cache:false}).then(function(response) {
						if(paging) {
							angular.forEach(response.data,function(datum) { $scope.data.results.push(datum); });
						} else {
							$scope.data.results = response.status == 200 ? response.data || [] : []; 
						} if(is.array(response.data)) { $scope.data.config.lastid = response.data.slice(-1).pop().id; }
					});
					
				}
			}; $scope.fetch(true);

			// # Define filter functions

			$scope.filter_do = function() {
				$scope.data.config.lastid = 0; $scope.fetch();
			};

			$scope.filter_clear = function() {
				angular.forEach($scope.data.filter,function(filter,index) {
					$scope.data.filter[index].value = null;
				}); $scope.data.config.lastid = 0; $scope.data.filter.active = false; $scope.fetch(true);
			};

			// # Define search functions

			$scope.search_do = function() {
				$scope.data.config.lastid = 0; $scope.fetch();
			};

			$scope.search_clear = function() {
				$scope.data.search.value = null; $scope.data.config.lastid = 0; $scope.data.search.active = false; $scope.fetch(true);
			};

			// # Define paging functions
			
			$scope.table_paging = function(page) {
				if(page == ($scope.data.results.length / $scope.data.config.limit)) {
					$scope.fetch(false,true); 
				}
			}; 

			$scope.table_reorder = function() { 
				$scope.data.config.page = 1; 
			};

			// # Define save functions

			$scope.save = function() {

				// # Reset errors and set loading state

				$scope.form.main.load = true;
				$scope.form.main.error = false;

				// # Call api
				
				if($scope.data.result.uid) {
					$http.put($scope.data.url + '/' + $scope.data.result.uid,{
						type: 							$scope.data.result.type,
						synonym:  						$scope.data.result.synonym,
						company:  						$scope.data.result.company,
						company_department:  			$scope.data.result.company_department,
						company_extra:  				$scope.data.result.company_extra,
						forename:  						$scope.data.result.forename,
						surname:  						$scope.data.result.surname,
						address:  						$scope.data.result.address,
						address_number:  				$scope.data.result.address_number,
						address_extra:  				$scope.data.result.address_extra,
						zipcode:  						$scope.data.result.zipcode,
						city:  							$scope.data.result.city,
						country:  						$scope.data.result.country,
						email:  						$scope.data.result.email,
						phone:  						$scope.data.result.phone,
						phone_mobile:  					$scope.data.result.phone_mobile,
						fax:  							$scope.data.result.fax,
						notify: 						$scope.data.result.notify,
						notify_ticket: 					$scope.data.result.notify_ticket,
						notify_ticket_region_nrw: 		$scope.data.result.notify_ticket_region_nrw,
						notify_ticket_region_munich: 	$scope.data.result.notify_ticket_region_munich,
						emergency: 						$scope.data.result.emergency,
						emergency_name: 				$scope.data.result.emergency_name,
						emergency_description:			$scope.data.result.emergency_description,
						emergency_phone: 				$scope.data.result.emergency_phone,
						objects: 						($scope.data.result.objects_s ? $scope.data.result.objects_s.join(',') : null || null),
						password: 						$scope.data.result.password,
						notice: 						$scope.data.result.notice
					}).then(function(response) {

						// # Reset loading state

						$scope.form.main.load = false;

						// # Notify

						$appService.do_notify('success');

						// # Update scope 

						if(response.status == 200) {
							$scope.data.result = angular.copy(response.data); 
						}

					},function(response) {

						// # Set error and loading state

						$scope.form.main.load = false;

						// # Evaluate errors

						if(response.data.errors) {
							angular.forEach(response.data.errors,function(error) {
								$scope.form.main[error.column].$setValidity('invalid',false);
							});
						}

					});
				} else {
					$http.post($scope.data.url,{
						type: 							$scope.data.result.type,
						synonym:  						$scope.data.result.synonym,
						company:  						$scope.data.result.company,
						company_department:  			$scope.data.result.company_department,
						company_extra:  				$scope.data.result.company_extra,
						forename:  						$scope.data.result.forename,
						surname:  						$scope.data.result.surname,
						address:  						$scope.data.result.address,
						address_number:  				$scope.data.result.address_number,
						address_extra:  				$scope.data.result.address_extra,
						zipcode:  						$scope.data.result.zipcode,
						city:  							$scope.data.result.city,
						country:  						$scope.data.result.country,
						email:  						$scope.data.result.email,
						phone:  						$scope.data.result.phone,
						phone_mobile:  					$scope.data.result.phone_mobile,
						fax:  							$scope.data.result.fax,
						notify: 						$scope.data.result.notify,
						notify_ticket_region_nrw: 		$scope.data.result.notify_ticket_region_nrw,
						notify_ticket_region_munich: 	$scope.data.result.notify_ticket_region_munich,
						emergency: 						$scope.data.result.emergency,
						emergency_name: 				$scope.data.result.emergency_name,
						emergency_description:			$scope.data.result.emergency_description,
						emergency_phone: 				$scope.data.result.emergency_phone,
						objects: 						($scope.data.result.objects_s ? $scope.data.result.objects_s.join(',') : null || null),
						password: 						$scope.data.result.password,
						password_send: 					$scope.data.result.password_send,
						notice: 						$scope.data.result.notice
					}).then(function(response) {

						// # Reset loading state

						$scope.form.main.load = false;

						// # Notify

						$appService.do_notify('success');

						// # Redirect

						if(response.status == 200) {
							$location.path($rootScope.route.url.replace(':uid',response.data.uid));
						}

					},function(response) {

						// # Set error and loading state

						$$scope.form.main.load = false;

						// # Evaluate errors

						if(response.data.errors) {
							angular.forEach(response.data.errors,function(error) {
								$scope.form.main[error.column].$setValidity('invalid',false);
							});
						}

					});
				}
			};

			// # Define delete function

			$scope.delete = function(data) {
				if(confirm($translate.instant('i18n_general_delete_confirm'))) {

					// # Call api
					
					$http.delete($scope.data.url + '/' + data.uid).then(function(response) {

						// # Notify

						$appService.do_notify('success');

						// # Renew data

						if(response.status == 200) {
							$scope.fetch(true);
						}

					},function(response) {

						// # Evaluate errors

						if(response.data.errors) {
							$appService.do_notify('error'); angular.forEach(response.data.errors,function(error) {
								$scope.form.main[error.column].$setValidity('invalid',false);
							});
						}

					});
				}
			};

			// # Define additional functions

			$scope.validate_synonym = function() {
				if($scope.data.result.synonym) {
					$http.get('/api/user/search/synonym:' + $scope.data.result.synonym).then(function(response) {
						if(response.status == 200 && (response.data.length > 1 || $scope.data.result.uid && $scope.data.result.uid != response.data[0].uid)) {
							$scope.form.main.synonym.$setValidity('exist',false);
						} else {
							$scope.form.main.synonym.$setValidity('exist',true);
						}
					},function(response) {
						$scope.form.main.synonym.$setValidity('exist',true);
					});
				}
			};

			$scope.validate_email = function() {
				if($scope.data.result.email) {
					$http.get('/api/user/search/email:' + $scope.data.result.email).then(function(response) {
						if(response.status == 200 && (response.data.length > 1 || $scope.data.result.uid && $scope.data.result.uid != response.data[0].uid)) {
							$scope.form.main.email.$setValidity('exist',false);
						} else {
							$scope.form.main.email.$setValidity('exist',true);
						}
					},function(response) {
						$scope.form.main.email.$setValidity('exist',true);
					});
				}
			};

		}
	},function() {
		console.error('Controller (' + $rootScope.route.controller + ') failed to fetch module configuration');
	}); 
});